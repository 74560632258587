.projects {
  margin: 20px;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
  padding: 10px 15px;
  color: #3a5a40;
}

.heading {
  text-align: center;
  font-size: 3rem;
}
.displayCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin-bottom: 80px;
  grid-gap: 10px;
}
.description {
  font-size: 20px;
  line-height: 1.5;
}
.launcher {
  border-radius: 10px;
  background-color: #3a5a40;
  border-color: #3a5a40;
  border: 0px;
  font-size: 18px;
  padding: 15px 30px;
  margin: 30px 0;
}
.launcher :hover {
  color: black;
}
button a {
  text-decoration: none;
  color: white;
}

#landscape {
  width: 90%;
}
@media (max-width: 750px) {
  .displayCard {
    grid-template-columns: 1fr;
    border-bottom: 2px solid black;
  }
}
