.navigation-bar {
  width: 100%;
  height: 100px;
  background-color: #344e41;
  position: fixed;
  top: 0;
}
.links {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100px;
}
.links ul {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 0;
}
.links ul li {
  margin-right: 20px;
}

.links ul li a {
  text-decoration: none;
  color: white;
  font-size: 25px;
}
