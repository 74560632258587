body {
    padding: 0;
    margin: 0;
    background-color: #fafaff;
 
}
@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,700&family=Lora&family=Montserrat:wght@500&family=Outfit:wght@400;700&family=PT+Serif&family=Playfair+Display:wght@700&family=Poppins:wght@500;700&family=Roboto+Serif:opsz@8..144&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Vollkorn:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,700&family=Lora&family=Montserrat:wght@500&family=Outfit:wght@400;700&family=PT+Serif&family=Playfair+Display:wght@700&family=Poppins:wght@500;700&family=Roboto+Serif:opsz@8..144&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Vollkorn:wght@600;700&display=swap');

* {
    font-family: 'Source Serif Pro', sans-serif;
}
