.Home {
  display: grid;
  align-content: center;
  width: 100%;
  height: 100vh;
}

.intro h1 {
  font-size: 85px;
  font-weight: 600;
  text-align: center;
  color: #3a5a40;
}
.intro p {
  text-align: center;
  font-size: 38px;
  color: #588157;
}
