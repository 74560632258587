.footer {
    background-color: #daddd8;
    padding: 80px 100px;   
    display: inline-block;
    justify-content: center;
    width: 100%;
}
.footer-container{
    display: flex;
    justify-content: center;
}
@media (max-width:900px){
    .footer-container {
        margin:60px 0;
    }

}

